import React from 'react';
import { Link } from 'gatsby';

const deskNav = ({ siteTitle }) => (
  <ul className='list dn flex-l'>
    <li>
      <Link
        to='/under-construction'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        Under Construction
      </Link>
    </li>
    <li>
      <Link
        to='/projects'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        Projects
      </Link>
    </li>
    <li>
      <Link
        to='/about'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        About
      </Link>
    </li>
    <li>
      <Link
        to='/journal'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        Journal
      </Link>
    </li>
    <li>
      <Link
        to='/contact'
        className='link f4 grey pl3 pr2 ml4 db'
        activeClassName='active'
        activeStyle={{ textDecoration: 'line-through' }}
      >
        Contact
      </Link>
    </li>
  </ul>
);

export default deskNav;
