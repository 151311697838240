import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import InstaIcon from "./InstaIcon";
import FbIcon from "./FbIcon";
import PinterestIcon from "./PinterestIcon";
import AwardsLogo from "./AwardsLogo";
import MbLogos from "./MbLogos";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        prismicGlobals {
          data {
            facebook_link {
              url
            }
            instagram_link {
              url
            }
            pinterest_link {
              url
            }
            email {
              url
            }
            phone_number {
              text
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer className="pl4 pr4 pl5-l pr5-l w-100 pb4">
        <div className="w-100 flex-l bt pt4 justify-between">
          <div className="f3 lh-copy tc tl-l gold">
            <div>Create + Construct</div>
            <a
              href={`tel:${data.prismicGlobals.data.phone_number.text}`}
              className="link gold"
            >
              {data.prismicGlobals.data.phone_number.text}
            </a>
          </div>
          <div className="tc tr-l mt4 mt0-l">
            <Link to="/projects" className="f3 link gold">
              View our projects
            </Link>
            <a
              href="https://bravedigital.nz"
              rel="noopener noreferrer"
              target="_blank"
              className="f5 dn db-l link gold tiempos i mt3"
            >
              This is a brave site
            </a>
          </div>
        </div>
        <div className="flex-l w-100 items-center justify-between mt4 tc">
          <AwardsLogo />

          <div className="mv4 mv0-l flex justify-center">
            <div className="flex justify-center mr4">
              <a
                href={data.prismicGlobals.data.instagram_link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="mr3"
              >
                <InstaIcon />
              </a>
              <a
                href={data.prismicGlobals.data.facebook_link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="mr3"
              >
                <FbIcon />
              </a>
              <a
                href={data.prismicGlobals.data.pinterest_link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="mr3"
              >
                <PinterestIcon />
              </a>
            </div>
            <MbLogos />
          </div>
          <a
            href="https://www.bravemedia.co.nz/"
            rel="noopener noreferrer"
            target="_blank"
            className="f5 db dn-l link gold tiempos i mt3"
          >
            This is a brave site
          </a>
        </div>
      </footer>
    )}
  />
);

export default Footer;
