import React from 'react';
import { Link } from 'gatsby';

const MenuLink = ({ linkTo, children }) => {
  return (
    <Link to={linkTo} className="link f2 grey pa3 db tiempos" activeClassName="active">
      {children}
    </Link>
  );
};

export default MenuLink;
