import React from 'react';
import { useSpring, animated, config } from 'react-spring';

const BraveHamburger = ({ menuState, headerColor }) => {
  const initialColor = headerColor === 'white' ? '#ffffff' : '#c19773';
  const { x, y, c } = useSpring({
    x: menuState ? 1 : 0,
    y: menuState ? 0 : 1,
    c: menuState ? '#c19773' : initialColor,
    config: config.wobbly
  });
  return (
    <animated.svg
      viewBox="0 0 96 96"
      height="1em"
      style={{
        overflow: 'visible',
        cursor: 'pointer',
        position: 'relative',
        fontSize: '2em',
        color: c
      }}
    >
      <g
        id="navicon"
        fill="none"
        stroke="currentColor"
        strokeWidth="14"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animated.line
          x1="7"
          y1="26"
          x2="89"
          y2="26"
          transform={x.interpolate(x => `translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`)}
        />
        <animated.line
          x1="7"
          y1="70"
          x2="89"
          y2="70"
          transform={x.interpolate(x => `translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`)}
        />
        <animated.line
          x1="7"
          y1="48"
          x2="89"
          y2="48"
          transform={x.interpolate(x => `translate(${x * -96})`)}
          opacity={y}
        />
      </g>
    </animated.svg>
  );
};

export default BraveHamburger;
