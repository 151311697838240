import React from 'react';
import SEO from '../components/seo';
import PropTypes from 'prop-types';
import Header from './nav/Header';
import Footer from './Footer';
import '../css/normalize.css';
import 'tachyons';
import '../css/main.css';

const Layout = ({ children, headerColor, transitionStatus, entry, exit }) => {
  return (
    <>
      <SEO keywords={[`Renovation`, `New Build`, `Master Builders`, `Auckland`, `New Zealand`, `Block NZ`, `The Block Builders`]} />
      <Header headerColor={headerColor} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired
};
